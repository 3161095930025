import { Component, input } from '@angular/core';
import { FlightRule } from '@shared/enums/airports/flight-rules.enum';
import { CloudDescription } from '@shared/enums/cloud-description.enum';

type FlightConditionIconCategory = FlightRule | 'MFR' | 'NO WX';
@Component({
  selector: 'pilot-flight-condition-icon',
  standalone: true,
  imports: [],
  templateUrl: './flight-condition-icon.component.html',
  styleUrl: './flight-condition-icon.component.scss'
})
export class FlightConditionIconComponent {
  cloudCover = input('Missing' , {
    transform: (cover: CloudDescription) => {
      if (cover === 'Sky Clear')  {
        return 'Clear';
      }
      return cover;
    }
  });
  category = input('NO WX', {
    transform: (c: FlightConditionIconCategory) => {
      if (c === 'MFR') {
        return 'mvfr';
      }
      if (c === 'NO WX') {
        return 'no-wx';
      }
      return c.toLowerCase();
    }
  });
}
