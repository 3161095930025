import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { AirportModel } from '@shared/models/airport/airport.model';
import { WindsAloftResponse } from '@shared/models/airport/cloudflare/wx/winds.model';
import { AirportSectionFetcher } from '@shared/services/airport/airport.service';
import { Observable, catchError, map, of, shareReplay, switchMap } from 'rxjs';
import { AdbService } from '../airports/adb/adb.service';

@Injectable({
  providedIn: 'root'
})
export class WindsService implements AirportSectionFetcher {
  private readonly airportServiceURL: string = environment.garmin.aviation.workerApiHost;

  private readonly _cache = new Map<string, Observable<AirportModel | null>>();

  constructor(
    private readonly http: HttpClient,
    private readonly adbService: AdbService
  ) {}

  getSectionDataById(airportId: string): Observable<AirportModel | null> {
    const upperAirportId = airportId?.toUpperCase();
    if (!this._cache.has(upperAirportId)) {
      this._cache.set(
        upperAirportId,
        this.adbService.getSectionDataById(upperAirportId).pipe(
          switchMap((airport) => {
            const airport0 = airport?.adbResponse?.AirportEntry.CcAirportInfoList.at(0);
            if (airport0 == null) {
              return of(null);
            }
            return this.getWindsAloftByLatLong(airport0.latDeg, airport0.lonDeg)
              .pipe(
                map((response) => ({ winds: response })),
                catchError((_error) => of(null)),
                shareReplay(1)
              );
          })
        )
      );
    }
    return this._cache.get(upperAirportId)!;
  }

  getWindsAloftByLatLong(lat: number, lon: number): Observable<WindsAloftResponse> {
    return this.http
      .get<WindsAloftResponse>(`${this.airportServiceURL}/v1/wx/winds`, {
      withCredentials: true,
      params: { lat, lon }
    });
  }
}
