import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const INTEGER_ERROR_STRING = { integer: 'Value must be an integer' };

export const integerValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.value != null && control.value.trim != null && control.value.trim() !== '') {
    const strVal = String(control.value);
    // if the value contains letters, remove them
    if (/[^0-9+]/.test(strVal)) control.setValue(strVal.replace(/[^0-9+]/g, ''), { emitEvent: false });
    const isInteger = Number.isInteger(Number(control.value));
    if (!isInteger) {
      return { integer: true };
    }
  }
  return null;
};

export function integerSnapValidator(min: number, max: number, required: boolean=false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value.toString() === '') {
      if (required) {
        return {integer: 'required'};
      } else {
        return null;
      }
    }
    let oldValue = control.value;
    if (isNaN(oldValue)) {
      oldValue = null;
      if (required) {
        control.setValue(oldValue, { emitEvent: false, onlySelf: true});
        return {integer: 'required'};
      } else {
        return {integer: 'required'};
      }
    }
    let newValue = oldValue;
    if (!Number.isInteger(newValue)) {
      newValue = +Math.round(newValue);
    }
    if (newValue < min) {
      newValue = min;
    }
    if (newValue > max) {
      newValue = max;
    }
    if (oldValue !== newValue) {
      control.setValue(newValue, { emitEvent: false, onlySelf: true });
    }
    return null;
  };
}
