import { inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlightOrchestratorUserSettingsService } from '@shared/services/flight-orchestrator/user-settings/flight-orchestrator-user-settings.service';
import { LatLonUtils, WPT_FORMATS } from '@shared/utils/lat-lon-utils';
import { decimalSnapValidator } from '@shared/validators/decimal';
import { nonSpecial } from '@shared/validators/non-special';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsWaypointsService {

  private readonly flightOrchestratorUserSettingsService = inject(FlightOrchestratorUserSettingsService);
  
  errorMap = {
    required: 'Required Value.',
    unknownError: 'Something went wrong.',
    specialCharError: 'Disallowed character in the input.',
    maxlength: 'Input is too long.',
    duplicate: 'Waypoint already exists.',
    singleWordError: 'Waypoint name should be a single word.'
  };

  wptForm = new FormGroup({
    name: new FormControl('', { validators: [Validators.required, Validators.maxLength(31), nonSpecial ], asyncValidators: [this.duplicateWaypointValidator()] }),
    comment: new FormControl('', { validators: Validators.maxLength(63) }),
    displayOnMap: new FormControl(true, { nonNullable: true })
  });

  latLonForm = new FormGroup(
    {
      format: new FormControl(WPT_FORMATS.DEG, { validators: Validators.required, nonNullable: true }),
      latDeg: new FormControl('', { validators: [Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG, 'deg')], nonNullable: true }),
      latMin: new FormControl('', { nonNullable: true }),
      latSec: new FormControl('', { nonNullable: true }),
      lonDeg: new FormControl('', { validators: [Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG, 'deg')], nonNullable: true }),
      lonMin: new FormControl('', { nonNullable: true }),
      lonSec: new FormControl('', { nonNullable: true }),
    },
    { updateOn: 'blur', validators: [LatLonUtils.maxLatValidator(), LatLonUtils.maxLonValidator()] }
  );

  brgDisForm = new FormGroup({
    lat: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    lon: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    rad: new FormControl<number | null>(null, { validators: [Validators.required, decimalSnapValidator(2, 0, 360)], nonNullable: true }),
    dist: new FormControl<number | null>(null, { validators: [Validators.required, decimalSnapValidator(5, 0, 100000000)], nonNullable: true }),
    identifier: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    qualifier: new FormControl('', { nonNullable: true }),
    locationType: new FormControl('', { nonNullable: true })
  });

  radRadForm = new FormGroup({
    lat1: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    lon1: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    identifier1: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    qualifier1: new FormControl('', { nonNullable: true }),
    locationType1: new FormControl('', { nonNullable: true }),
    rad1: new FormControl<number | null>(null, { validators: [Validators.required, decimalSnapValidator(2, 0, 360)], nonNullable: true }),
    lat2: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    lon2: new FormControl(-1, { validators: [Validators.required], nonNullable: true }),
    identifier2: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    qualifier2: new FormControl('', { nonNullable: true }),
    locationType2: new FormControl('', { nonNullable: true }),
    rad2: new FormControl<number | null>(null, { validators: [Validators.required, decimalSnapValidator(2, 0, 360)], nonNullable: true })
  });

  duplicateWaypointValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const controlValue = control.value?.trim().toUpperCase();
      return this.flightOrchestratorUserSettingsService.getWaypointsByName(controlValue)
      .pipe(map((results) => {
        if (results?.find((result) => result.name.toUpperCase() === controlValue) != null) {
          return { duplicate: true };
        }
        return null;
      }));
    };
  }
}
