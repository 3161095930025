import { Unit } from '@shared/pipes/unit-converter/unit-converter.pipe';
import { differenceInCalendarDays, fromUnixTime, getDayOfYear, hoursToMinutes, minutesToHours, parse } from 'date-fns';
import { format, formatInTimeZone, toZonedTime, fromZonedTime } from 'date-fns-tz';
interface TimeUnits {
  date?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}
interface SunCalcData {
  unixTimeOrDate: number | Date;
  lat: number;
  lon: number;
  tzName?: string;
}
export interface SunCalcRawData {
  unixTimeOrDate: number | Date;
  aopaLat?: number;
  aopaLon?: number;
  aopaTzName?: string;
  aopaOffset?: number;
  adbLat: number;
  adbLon: number;
  adbOffset: number;
}

export interface SunInfo {
  sunrise: string;
  sunriseAmPm: string;
  sunriseTimezone: string;
  sunset: string;
  sunsetAmPm: string;
  sunsetTimezone: string;
}

export interface PreferredDurationInfo {
  durationStart: string;
  durationEnd: string;
  durationStartAmPm: string;
  durationEndAmPm: string;
  durationStartTimezone: string;
  durationEndTimezone: string;
  durationStartUnit: string;
  durationEndUnit: string;
}
export interface PreferredTimeDisplayInfo {
  timeDisplayString: string;
  timeDisplayAmPm: string;
  timeDisplayZone: string;
  timeDisplayUnit: string;
}

enum SunType {
  Normal,
  PolarDay,
  PolarNight,
}

export class DateTimeUtils {

  // Formatting strings: https://date-fns.org/v2.29.3/docs/format
  static readonly DATE_FORMAT = 'dd MMM yyyy';
  static readonly NUMERIC_DATE_FORMAT = 'yyyy-MM-dd';
  static readonly TIME_FORMAT = 'HH:mm z';
  static readonly TIME_FORMAT_12H = 'hh:mm aa z';
  static readonly DAY_TIME_FORMAT = `EEE do ${DateTimeUtils.TIME_FORMAT}`;
  static readonly DAY_ONLY_TIME_FORMAT = `EEE HH:mm`;
  static readonly DAY_DATE_TIME_FORMAT = `MMM do HH:mm`;
  static readonly TIME_ONLY_FORMAT = 'HH:mm';
  static readonly TIMEZONE_FORMAT = `z`;
  static readonly DATE_TIME_FORMAT = 'dd MMM yyyy HH:mm z';
  static readonly ISO_UTC_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'';
  static readonly MONTH_DATE_TIME = 'MMM dd HH:mm';
  static readonly FLIGHTS_DATE_FORMAT = "dd-MMM-yyyy";
  static readonly FLIGHTS_TABLE_DATE_FORMAT24 = 'd MMM HH:mm z';  // 24 hours
  static readonly FLIGHTS_TABLE_DATE_FORMAT12 = 'd MMM hh:mmaa z'; // 12 hour

  static getStringFromUnixTime(unixTime: number, pattern: string): string {
    return format(fromUnixTime(unixTime), pattern);
  }

  static timeRange(startUnix: number, endUnix: number): string {
    // using endUnix as the first argument is on purpose: https://date-fns.org/v2.29.3/docs/differenceInCalendarDays
    const sameDay = differenceInCalendarDays(fromUnixTime(endUnix), fromUnixTime(startUnix)) === 0;
    const start = DateTimeUtils.getStringFromUnixTime(startUnix, DateTimeUtils.DAY_TIME_FORMAT);
    const end = DateTimeUtils.getStringFromUnixTime(endUnix, sameDay ? DateTimeUtils.TIME_FORMAT : DateTimeUtils.DAY_TIME_FORMAT);
    return `${start} to ${end}`;
  }

  static unixToLocalDateTimeString(unixTime: number): string {
    return DateTimeUtils.getStringFromUnixTime(unixTime, this.DATE_TIME_FORMAT);
  }

  static unixToLocalTimeString(unixTime: number): string {
    return DateTimeUtils.getStringFromUnixTime(unixTime, this.TIME_FORMAT);
  }

  static timeRangeDayValues(startUnix: number, endUnix: number): [string, string, string] {
    const sameDay = differenceInCalendarDays(fromUnixTime(endUnix), fromUnixTime(startUnix)) === 0;
    const startDate = this.getStringFromUnixTime(startUnix, this.DAY_ONLY_TIME_FORMAT);
    const endDate = this.getStringFromUnixTime(endUnix, sameDay ? this.TIME_ONLY_FORMAT : this.DAY_ONLY_TIME_FORMAT);
    const timezone = this.getStringFromUnixTime(startUnix, this.TIMEZONE_FORMAT);
    return [startDate, endDate, timezone];
  }

  static timeRangeDayDateValues(startUnix: number, endUnix: number): string {
    const sameDay = differenceInCalendarDays(fromUnixTime(endUnix), fromUnixTime(startUnix)) === 0;
    const start = this.getStringFromUnixTime(startUnix, this.DAY_DATE_TIME_FORMAT);
    const end = this.getStringFromUnixTime(endUnix, sameDay ? this.TIME_ONLY_FORMAT : this.DAY_DATE_TIME_FORMAT);
    return `${start} to ${end}`;
  }

  static timezoneValue(unixTime: number): string {
    const timezone = this.getStringFromUnixTime(unixTime, this.TIMEZONE_FORMAT);
    return timezone;
  }

  static monthDateTimeValues(unixTime: number): [string, string] {
    const monthDateTime = this.getStringFromUnixTime(unixTime, this.MONTH_DATE_TIME);
    const timezone = this.getStringFromUnixTime(unixTime, this.TIMEZONE_FORMAT);
    return [monthDateTime, timezone];
  }

  static timeValues(unixTime: number): [string, string] {
    const time = this.getStringFromUnixTime(unixTime, this.TIME_ONLY_FORMAT);
    const timezone = this.getStringFromUnixTime(unixTime, this.TIMEZONE_FORMAT);
    return [time, timezone];
  }

  /**
    Takes an ISO date time string in UTC time zone, modifies it according to the provided values and the
    specified IANA time zone for those values, and then returns an ISO date time string in UTC time zone

    TODO: Add support for year, month, and date modifications
  */
  static modifyIsoUtcString(isoString: string, values: TimeUnits, ianaTimeZone: string = 'UTC'): string {
    let date = parse(isoString, this.ISO_UTC_FORMAT, new Date());

    if (values.date != null) {
      date.setDate(values.date);
    }

    if (values.hours != null) {
      date.setHours(values.hours);
    }

    if (values.minutes != null) {
      date.setMinutes(values.minutes);
    }

    if (values.seconds != null) {
      date.setSeconds(values.seconds);
    }

    if (ianaTimeZone !== 'UTC') {
      date = fromZonedTime(date, ianaTimeZone);
    }

    return ianaTimeZone === 'UTC' ? format(date, this.ISO_UTC_FORMAT) : formatInTimeZone(date, 'UTC', this.ISO_UTC_FORMAT);
  }

  /**
   * Modifies the hours, minutes, and/or seconds of a Date and returns the modified date's ISO string.
   *
   * @param date the Date, ISO string, or Unix time to modify
   * @param values modifications to hours, minutes, and/or seconds
   * @param ianaTimeZone the time zone of the Date; UTC by default
   * @returns the ISO string of the modified Date
   */
  static modifyIsoStringForDate(date: Date | string | number, values: TimeUnits, ianaTimeZone: string = 'UTC'): string {
    return DateTimeUtils.modifyIsoUtcString(formatInTimeZone(date, ianaTimeZone, this.ISO_UTC_FORMAT), values);
  }

  /**
    Takes an ISO date time string in UTC time zone and converts it to an equivalent ISO date time string in the specified time zone.
  */
  static convertIsoUtcStringToZonedIsoString(isoString: string, ianaTimeZone: string, formatPattern: string = DateTimeUtils.ISO_UTC_FORMAT): string {
    return formatInTimeZone(
      isoString,
      ianaTimeZone,
      formatPattern
    );
  }

  /**
    Takes an ISO date time string and converts it to an equivalent HH:mm string.
    The IANA time zone of the original date time string must be specified.
  */
  static isoDateTimeStringToHHmmString(isoString: string, timeZone: string, timePreference: boolean = false): string {
    const date = toZonedTime(isoString, timeZone);
    const minuteFormat = `${date.getMinutes().toString().padStart(2, '0')}`;
    let hours = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    if (timePreference) {
      //12 hour format
      let formattedHours = 0;
      //first if: 12 to 23
      //second if:  24 to 11
      if (date.getHours() >= 12 && date.getHours() < 24) {
        if (date.getHours() > 12) {
          formattedHours = date.getHours() - 12;
        } else {
          formattedHours = date.getHours();
        }
        hours = `${formattedHours.toString().padStart(2, '0')}:${minuteFormat} PM`;
      } else {
        formattedHours = date.getHours();
        hours = `${formattedHours.toString().padStart(2, '0')}:${minuteFormat} AM`;
      }
    }
    return hours;
  }

  /**
    Takes a number of minutes and converts it to an equivalent HH:mm or HH + mm string
  */
  static minutesToHHmmString(minutes: number, usePlusFormat: boolean = false): string {
    const hours = minutesToHours(minutes);
    const leftOverMinutes = minutes - hoursToMinutes(hours);
    if (usePlusFormat) {
      return `${hours.toString()}+${leftOverMinutes.toString().padStart(2, '0')}`;
    } else {
      return `${hours.toString().padStart(2, '0')}:${leftOverMinutes.toString().padStart(2, '0')}`;
    }
  }

  /**
   * Takes in a date string and calculates the time of day and returns it in minutes
   * @param dateString
   * @returns input time in minutes from midnight in the given time zone (UTC by default)
   */
  static stringToMinutes(dateString: string, timeZone: string = 'UTC'): number {
    const [hours, minutes] = this.isoDateTimeStringToHHmmString(dateString, timeZone).split(':');
    return Number(hours) * 60 + Number(minutes);
  }

  /**
   * Takes in a Date in the local time zone and converts to a formatted string in
   * the specified IANA time zone.
   * @param date
   * @param timeZone
   * @param useLocalTime
   * @param useTwentyFourHour
   * @returns input time converted to IANA time zone
   */
  static getStringFromLocalDate(date: Date, timeZone: string, useLocalTime: boolean = true, useTwentyFourHour: boolean = true): string {
    const pattern: string = (!useLocalTime || useTwentyFourHour) ? DateTimeUtils.FLIGHTS_TABLE_DATE_FORMAT24 : DateTimeUtils.FLIGHTS_TABLE_DATE_FORMAT12;
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, pattern, { timeZone: timeZone });
  }

  /**
   * Takes in a time string in HH:MM format. It converts this string to two numbers. One for hours
   * and one for minutes. It then converts the hours to minutes and returns the total.
   * @param hhmm
   * @param usePlusFormat
   * @returns the total number of minutes
   * @example
   * '14:30' is the string passed in. Hour is set to 14 and minute is 30. Hour is turned to 840. The total 870 is returned.
   */
  static getMinutesFromHHMMString(hhmm: string, usePlusFormat: boolean = false): number {
    const splitString = hhmm.split(usePlusFormat ? '+' : ':');
    const hour = Number(splitString[0]);
    const minute = Number(splitString[1]);
    return hour * 60 + minute;
  }

  /**
 * Takes in an IANA time zone and returns the associated time zone abbreviation
 * @param timeZone the IANA time zone
 * @param date the date for which to retrieve the time zone, affecting daylight savings (optional; defaults to today)
 * @returns the time zone abbreviation (ex: 'MST')
 */
  static getTimeZoneAbbreviation(timeZone: string, date: Date = new Date()): string {
    return date.toLocaleTimeString('en-us', { timeZone, timeZoneName: 'short' }).split(' ')[2];
  }

  /**
   * Returns 'AM' or 'PM' depending on the input time and timezone.
   * @param time an ISO timestamp, Unix timestamp, or Date object
   * @param timezone an IANA timezone
   * @returns 'AM' or 'PM'
   */
  static getAmPm(time: string | number | Date, timezone: string): string {
    return formatInTimeZone(time, timezone, 'aa');
  }

  static getMon(preferredLocale: string): string[] {
    return Array.from(Array(12), (e, i) => new Date(2023, i, 1).toLocaleString(preferredLocale, { month: 'short' }));
  }

  static getDay(preferredLocale: string): string[] {
    return Array.from(Array(7), (e, i) => { const now = new Date(); return new Date(now.setDate(now.getDate() - now.getDay() + i)).toLocaleString(preferredLocale, { weekday: 'short' }); });
  }

  static preferredTimeRangeDayValues(
    startUnix: number, endUnix: number, preferredUtcLocalTime: Unit, preferredTimeFormat: Unit, dateFormat: string, timezone?: string): PreferredDurationInfo {
    const sameDay = differenceInCalendarDays(fromUnixTime(endUnix), fromUnixTime(startUnix)) === 0;
    const startInfo = this.getFormattedUserPreferenceDatetime(startUnix, preferredUtcLocalTime, preferredTimeFormat, dateFormat, timezone);
    const endInfo = this.getFormattedUserPreferenceDatetime(endUnix, preferredUtcLocalTime, preferredTimeFormat, sameDay ? '' : dateFormat, timezone);
    return {
      durationStart: startInfo.timeDisplayString,
      durationEnd: endInfo.timeDisplayString,
      durationStartAmPm: startInfo.timeDisplayAmPm,
      durationEndAmPm: endInfo.timeDisplayAmPm,
      durationStartTimezone: startInfo.timeDisplayZone,
      durationEndTimezone: endInfo.timeDisplayZone,
      durationStartUnit: startInfo.timeDisplayUnit,
      durationEndUnit: endInfo.timeDisplayUnit
    };
  }

  /**
  * Takes in unix time number and returns formatting based on unit time preferences for utcLocalTime and timeFormat
  * @param unixTime number
  * @param utcLocalTime Unit enum
  * @param timeFormat Unit enum
  * @param dateformat string
  * returns [formatDatetime, formatZone]
  */
  static getFormattedUserPreferenceDatetime(
    datetimeUnix: number, preferredUtcLocalTime: Unit, preferredTimeFormat: Unit, dateFormat: string, timezone?: string): PreferredTimeDisplayInfo {
    const ulocale = 'en-US'; // hard coded for now - should be on of the general time preferences (along with UtcLocalTIme, preferredTimeFormat) in the future
    let formatString: string;
    const dateString = dateFormat;
    formatString = dateString;
    let timeString = '';
    let formatAmPm = '';
    let formatDatetime = '';
    let formatZone = '';
    let formatUnit = '';
    if (preferredTimeFormat === Unit.TWELVE_HOUR) {
      timeString = ' h:mmaa';
    } else {
      timeString = ' H:mm';
    }
    if (preferredUtcLocalTime === Unit.LOCAL) {
      formatString = dateString.concat(timeString.toString());
      if (timezone == null) {
        formatDatetime = this.getStringFromUnixTime(datetimeUnix, formatString);
        formatZone = this.getStringFromUnixTime(datetimeUnix, this.TIMEZONE_FORMAT);
      } else {
        formatDatetime = formatInTimeZone(fromUnixTime(datetimeUnix), timezone, formatString);
        formatZone = this.getTimeZoneAbbreviation(timezone);
      }
      if (preferredTimeFormat === Unit.TWELVE_HOUR) {
        formatAmPm = formatDatetime.slice(-2);
        formatDatetime = formatDatetime.substring(0, formatDatetime.length - 2);
        formatUnit = `${formatAmPm} ${formatZone}`.trim();
      }
    }
    if (preferredUtcLocalTime === Unit.UTC) {
      const zdDate = fromUnixTime(datetimeUnix);
      const dYear = zdDate.getUTCFullYear();
      const dMon = zdDate.getUTCMonth();
      const dMonth = DateTimeUtils.getMon(ulocale)[dMon];
      const dDay = zdDate.getUTCDay();
      const dWeekday = DateTimeUtils.getDay(ulocale)[dDay];
      const dDate = zdDate.getUTCDate();
      const dHour = zdDate.getUTCHours();
      const dMinutes = zdDate.getUTCMinutes();
      let formatUTCDate = dMonth?.concat(' ').concat(dDate.toString()).concat(' ');
      let formatUTCTime = dHour?.toString().concat(':').concat((dMinutes).toString().padStart(2, '0'));
      if (dateFormat === 'MMM d') {
        formatUTCDate = dMonth?.concat(' ').concat(dDate.toString()).concat(' ');
      } else if (dateFormat === 'EEE') {
        formatUTCDate = dWeekday?.concat(' ');
      } else if (dateFormat === 'MMM d, yyyy') {
        formatUTCDate = dMonth?.concat(' ').concat(dDate.toString()).concat(', ').concat(dYear.toString()).concat(' ');
      } else if (dateFormat === 'd-MMM') {
        formatUTCDate = dDate.toString().concat('-').concat(dMonth).concat(' ');
      } else {
        formatUTCDate = ' ';
      }
      if (preferredTimeFormat === Unit.TWENTY_FOUR_HOUR) {
        formatUTCTime = dHour?.toString().concat(':').concat(dMinutes.toString().padStart(2, '0'));
      } else if (preferredTimeFormat === Unit.TWELVE_HOUR && dHour < 12) {
        formatUTCTime = dHour?.toString().concat(':').concat(dMinutes.toString().padStart(2, '0'));
        formatAmPm = 'AM';
      } else if (preferredTimeFormat === Unit.TWELVE_HOUR && dHour === 12) {
        formatUTCTime = dHour?.toString().concat(':').concat(dMinutes.toString().padStart(2, '0'));
        formatAmPm = 'PM';
      } else {
        const pmHour = dHour - 12;
        formatUTCTime = pmHour?.toString().concat(':').concat(dMinutes.toString().padStart(2, '0'));
        formatAmPm = 'PM';
      }
      formatDatetime = formatUTCDate?.concat(formatUTCTime.toString());
      formatZone = 'Z';
      formatUnit = `${formatAmPm} ${formatZone}`.trim();
    }
    return {
      timeDisplayString: formatDatetime,
      timeDisplayAmPm: formatAmPm,
      timeDisplayZone: formatZone,
      timeDisplayUnit: formatUnit
    };
  }

  static sameDay(start: Date | string | number, end: Date | string | number, timeZone: string = 'UTC'): boolean {
    const yearMonthDayFormat = 'yMMdd';
    return formatInTimeZone(start, timeZone, yearMonthDayFormat) === formatInTimeZone(end, timeZone, yearMonthDayFormat);
  }

  static sameYear(startUnix: number, endUnix: number, preferredUtcLocalTime: Unit): boolean {
    let startDate = DateTimeUtils.getStringFromUnixTime(startUnix, DateTimeUtils.NUMERIC_DATE_FORMAT);
    let endDate = DateTimeUtils.getStringFromUnixTime(endUnix, DateTimeUtils.NUMERIC_DATE_FORMAT);
    if (preferredUtcLocalTime === Unit.UTC) {
      startDate = DateTimeUtils.getStringFromUnixTime(startUnix, DateTimeUtils.ISO_UTC_FORMAT);
      endDate = DateTimeUtils.getStringFromUnixTime(endUnix, DateTimeUtils.ISO_UTC_FORMAT);
    }
    return startDate.substring(0, 4) === endDate.substring(0, 4);
  }

  static calcTimezoneAOPA(tzHourOffset: number): string {
    if (tzHourOffset % 1 !== 0) {
      return this.getTzOffset(tzHourOffset);
    }
    const tzHour = Math.abs(tzHourOffset);
    let tzSign = 'Etc/GMT+';
    if (tzHourOffset > 0) {
      tzSign = 'Etc/GMT-';
    } else {
      tzSign = 'Etc/GMT+';
    }
    const tzString = tzSign.concat(tzHour.toString());
    return tzString;
  }

  static calcTimezoneADB(tzHourOffset: number): string {
    if (tzHourOffset % 1 !== 0) {
      return this.getTzOffset(tzHourOffset);
    }
    let tzOffset = tzHourOffset;
    if (tzHourOffset < -12) {
      tzOffset = Math.abs(tzHourOffset + 12);
    } else if (tzHourOffset > 12) {
      tzOffset = tzHourOffset - 12;
    }
    const tzHour = Math.abs(tzOffset);
    let tzSign = 'Etc/GMT+';
    if (tzOffset > 0) {
      tzSign = 'Etc/GMT-';
    } else {
      tzSign = 'Etc/GMT+';
    }
    const tzString = tzSign.concat(tzHour.toString());
    return tzString;
  }

  static getTzOffset(offset: number): string {
    if (offset === -9.5) {
      return 'Pacific/Marquesas';
    } else if (offset === -3.5) {
      return 'America/St_Johns';
    } else if (offset === 5.5) {
      return 'Asia/Calcutta';
    } else if (offset === 5.75) {
      return 'Asia/Kathmandu';
    } else if (offset === 6.5) {
      return 'Indian/Cocos';
    } else if (offset === 8.5) {
      return 'Asia/Pyongyang';
    } else if (offset === 9.5) {
      return 'Australia/Darwin';
    } else if (offset === 10.5) {
      return 'Australia/Adelaide';
    } else {
      return 'UTC';
    }
  }

  static getSunInfo(data: SunCalcRawData, utcOrLocal: Unit = Unit.LOCAL, timeFormat: Unit = Unit.TWENTY_FOUR_HOUR): SunInfo {
    let sunCalcData: SunCalcData | null;
    let sunInfo: SunInfo = {
      sunrise: '',
      sunriseAmPm: '',
      sunset: '',
      sunsetTimezone: '',
      sunsetAmPm: '',
      sunriseTimezone: ''
    };
    let tzString = '';

    if (data != null) {
      if (utcOrLocal === Unit.UTC) {
        tzString = 'UTC';
      } else if (data.aopaTzName != null && data.aopaTzName.substring(0, 7) !== 'Etc/GMT') {
        tzString = data.aopaTzName;
      } else if (data.adbOffset != null && Math.abs(data.adbOffset) !== 15) {
        tzString = this.calcTimezoneADB(data.adbOffset);
      } else if (data.aopaOffset != null) {
        tzString = this.calcTimezoneAOPA(data.aopaOffset);
      } else {
        tzString = 'UTC';
      }
      sunCalcData = {
        unixTimeOrDate: data.unixTimeOrDate,
        lat: data.aopaLat ?? data.adbLat,
        lon: data.aopaLon ?? data.adbLon,
        tzName: tzString
      };
      sunInfo = this.populateSunInfo(sunCalcData, timeFormat);
    }
    return sunInfo;
  }

  private static populateSunInfo(data: SunCalcData, timeFormat: Unit): SunInfo {
    // eslint-disable-next-line prefer-const
    let [sunrise, sunrise_type] = this.calculateSunRiseSunSet(data, 'sunrise');
    // eslint-disable-next-line prefer-const
    let [sunset, sunset_type] = this.calculateSunRiseSunSet(data, 'sunset');
    const formatString = timeFormat === Unit.TWELVE_HOUR ? DateTimeUtils.TIME_FORMAT_12H : DateTimeUtils.TIME_FORMAT;

    // adjust times that cross over days
    if (sunset < sunrise) {
      if (data.lon < 0) {
        sunset += 86400;
      } else {
        sunrise += 86400;
      }
    }

    const formatter = (time: number, pattern: string): string => {
      return formatInTimeZone(fromUnixTime(time), data.tzName ?? 'Etc/GMT', pattern);
    };
    const rise = sunrise_type === SunType.Normal ? formatter(sunrise, formatString) : 'None';
    const sunriseTimezone = rise === 'None' ? '' : rise.split(' ').at(-1)!;
    const set = sunset_type === SunType.Normal ? formatter(sunset, formatString) : 'None';
    const sunsetTimezone = set === 'None' ? '' : set.split(' ').at(-1)!;

    const getAmPm = (time: string): string => {
      if (time !== 'None') {
        if (time.includes('AM')) return 'AM';
        if (time.includes('PM')) return 'PM';
      }
      return '';
    };
    return {
      sunrise: rise.split(' ')[0],
      sunriseAmPm: getAmPm(rise),
      sunriseTimezone,
      sunset: set.split(' ')[0],
      sunsetAmPm: getAmPm(set),
      sunsetTimezone
    };
  }

  // This code is from Garmin Pilot:
  // - https://itstash.garmin.com/projects/AVCLOUD/repos/zoot.data-services.cpp/browse/dcGridCreator/SunDataCalculator.cpp#217
  // - https://itstash.garmin.com/projects/AVCLOUD/repos/zoot.data-services.cpp/browse/dcGridCreator/CelestialUtil.cpp#54,74
  static calculateSunRiseSunSet(data: SunCalcData, sunEvent: 'sunrise' | 'sunset'): [number, SunType] {
    const TO_RAD = (Math.PI / 180);
    const TO_DEG = (180 / Math.PI);
    // convert time to UTC and get day of year
    let date = data.unixTimeOrDate instanceof Date ? data.unixTimeOrDate : fromUnixTime(data.unixTimeOrDate);
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    const dayOfYear = getDayOfYear(date);

    // calculate more precise day of the year (i.e, with a fractional portion)
    const lonHour = data.lon / 15;
    let t = dayOfYear;
    if (sunEvent === 'sunrise') {
      t += ((6 - lonHour) / 24);
    } else {
      t += ((18 - lonHour) / 24);
    }

    // Sun's mean anomaly
    const M = (0.9856 * t) - 3.289;

    // Sun's true longitude (adjust into [0,360) degree range)
    let L = M + (1.916 * Math.sin(M * TO_RAD)) + (0.020 * Math.sin(2 * M * TO_RAD)) + 282.634;
    L = ((L % 360) + 360) % 360;

    // Sun's right ascension (adjust into [0,360) degree range)
    let RA = TO_DEG * Math.atan(0.91764 * Math.tan(L * TO_RAD));
    RA = ((RA % 360) + 360) % 360;

    // adjust right ascension to be in same quadrant as true longitude
    const LQuadrant = Math.trunc(L / 90) * 90;
    const RAquadrant = Math.trunc(RA / 90) * 90;
    RA += (LQuadrant - RAquadrant);

    // convert right ascension into hours
    RA /= 15;

    // Sun's declination
    const sinDecl = 0.39782 * Math.sin(L * TO_RAD);
    const cosDecl = Math.cos(Math.asin(sinDecl));

    // Sun's local hour angle
    const zenith = 90.833333; // from DCI's SunDataCalculator.cpp
    const lat = (data.lat > 89.5) ? 89.5 : (data.lat < -89.5 ? -89.5 : data.lat); // truncate latitude
    const cosH = (Math.cos(zenith * TO_RAD) - (sinDecl * Math.sin(lat * TO_RAD))) /
      (cosDecl * Math.cos(lat * TO_RAD));

    // there's only a sunrise/sunset if the local hour angle is between (-1,1)
    // - cosH <= -1 means sun never sets at this location (on the specified date)
    // - cosH >=  1 means sun never rises at this location (on the specified date)
    const type = cosH <= -1 ? SunType.PolarDay :
      cosH >= 1 ? SunType.PolarNight :
        SunType.Normal;
    if (type === SunType.Normal) {
      // Sun's local hour
      let H = TO_DEG * Math.acos(cosH);
      if (sunEvent === 'sunrise') {
        H = 360 - H;
      }
      H /= 15;

      // local mean time of rising/setting
      const T = H + RA - (0.06571 * t) - 6.622;

      // adjust back to UTC and adjust to [0, 24] range
      let UT = T - lonHour;
      UT = ((UT % 24) + 24) % 24;

      // convert to hours, minutes, and seconds
      let hr = Math.trunc(UT);
      UT = (UT - hr) * 60.0;
      let mn = Math.trunc(UT);
      UT = (UT - mn) * 60.0;
      const sec = Math.trunc(UT);

      // round up the seconds to the next minute
      if (sec >= 30) {
        mn = mn + 1;
        if (mn > 59) {
          mn = 0;
          hr = hr + 1;
          if (hr > 23) {
            hr = 0;
          }
        }
      }

      // update the UTC date with the sunrise/sunset time
      date.setUTCHours(hr);
      date.setUTCMinutes(mn);
      date.setUTCSeconds(0);

      // turn the date back into a unix timestamp
      return [Math.trunc(date.getTime() / 1000), type];
    }
    return [-1, type];
  }

  static getCalculatedDepartureTime(departureTime: string, ete: number, departureTz: string, arrivalTz: string): string {
    const date = toZonedTime(departureTime, departureTz);
    const calcDepTime = this.modifyIsoUtcString(
      departureTime,
      {
        date: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes() - ete
      },
      arrivalTz
    );
    return calcDepTime;
  }
}
