import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { AirportModel } from '@shared/models/airport/airport.model';
import { ForecastDiscussionResponse } from '@shared/models/airport/cloudflare/wx/forecast.model';
import { Observable, catchError, map, of, shareReplay, switchMap } from 'rxjs';
import { DailyResponse } from '../../../features/airport/forecast/models/daily-forecast.model';
import { HourlyResponse } from '../../../features/airport/forecast/models/hourly-forecast.model';
import { AdbService } from '../airports/adb/adb.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {
  private readonly airportServiceURL: string = environment.garmin.aviation.workerApiHost;

  private readonly _cache = new Map<string, Observable<AirportModel | null>>();

  constructor(
    private readonly http: HttpClient,
    private readonly adbService: AdbService
  ) {}

  getSectionDataById(airportId: string): Observable<AirportModel | null> {
    const upperAirportId = airportId?.toUpperCase();
    if (!this._cache.has(upperAirportId)) {
      this._cache.set(
        upperAirportId,
        this.adbService.getSectionDataById(upperAirportId).pipe(
          switchMap((airport) => {
            const airport0 = airport?.adbResponse?.AirportEntry.CcAirportInfoList.at(0);
            if (airport0 == null) {
              return of(null);
            }
            return this.getForecast(airport0.latDeg, airport0.lonDeg);
          })
        )
      );
    }
    return this._cache.get(upperAirportId)!;
  }

  getSectionDataByLatLong(stationId: string, lat: number, long: number): Observable<AirportModel | null> {
    const upperStationId = stationId?.toUpperCase();
    if (!this._cache.has(upperStationId)) {
      this._cache.set(
        upperStationId,
        this.adbService.getSectionDataById(upperStationId).pipe(
          switchMap(() => {
            return this.getForecast(lat, long);
          })
        )
      );
    }
    return this._cache.get(upperStationId)!;
  }

  getDailyWeatherForecast(lat: number, lon: number): Observable<DailyResponse | null> {
    return this.http
      .get<DailyResponse>(`${this.airportServiceURL}/v1/wx/forecast-daily`, {
      withCredentials: true,
      params: { lat: lat, lon: lon }
    })
      .pipe(
        catchError((_error) => of(null)),
      );
  }

  getHourlyWeatherForecast(lat: number, lon: number): Observable<HourlyResponse | null> {
    return this.http
      .get<HourlyResponse>(`${this.airportServiceURL}/v1/wx/forecast-hourly`, {
      withCredentials: true,
      params: { lat: lat, lon: lon }
    })
      .pipe(
        catchError((_error) => of(null)),
      );
  }

  private getForecast(lat: number, lon: number) : Observable<AirportModel | null> {
    return this.http
      .get<ForecastDiscussionResponse>(`${this.airportServiceURL}/v1/wx/forecast-discussion`, {
      withCredentials: true,
      params: { lat: lat, lon: lon }
    })
      .pipe(
        map((response) => ({ forecastDiscussion: response })),
        catchError((_error) => of(null)),
        shareReplay(1)
      );
  }
}
