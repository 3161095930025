<div class="search">
  <div class="search-input">
    <fly-input
      [formControl]="searchControl"
      [autocomplete]="false"
      (focusin)="showContent()"
      (keydown.Enter)="handleEnter()"
      name="search"
      placeholder="Search">
      <fly-icon
        [icon]="Icons.Search"
        slot="icon-left"></fly-icon>
      @if (currentSearchState !== State.NoSelection) {
        <fly-icon
          class="cancel-icon"
          [icon]="Icons.Cancel"
          (click)="onCancelSearch()"
          slot="icon-right"></fly-icon>
      }
    </fly-input>
  </div>
</div>

@if (showSearchContent) {
  <div class="search-content"
    flyIgnoreMapClick>
      @switch (currentSearchState) {
        @case (State.Loaded) {
          <!-- AIRPORTS SECTION -->
          @if (airports().length > 0) {
              <span class="section-header">
                <span>Airports</span>
                @if (airports().length > 5) {
                  <button
                    flyButton
                    [options]="{
                      size: 'medium',
                      style: 'ghost'
                    }"
                    (click)="showMoreAirports.set(!showMoreAirports())">
                    {{ airportsToDisplay().label }}
                  </button>
                }
              </span>
              <div class="search-results">
                @for (airport of airportsToDisplay().airports; track $index) {
                  <pilot-airport-search-card
                    class="search-card"
                    (click)="onSelectAirport(airport)"
                    [airport]="airport">
                  </pilot-airport-search-card>
                }
              </div>
          }
          <!-- WAYPOINTS SECTION -->
          @if (waypoints().length > 0) {
            <span class="section-header">
              <span>NAVAIDs</span>
              @if(waypoints().length > 5) {
                <button
                  flyButton
                  [options]="{
                    size: 'medium',
                    style: 'ghost'
                  }"
                  (click)="showMoreWaypoints.set(!showMoreWaypoints())">
                  {{ waypointsToDisplay().label }}
                </button>
              }
            </span>
            <div class="search-results">
              @for (waypoint of waypointsToDisplay().waypoints; track $index) {
                <pilot-search-card
                  class="search-card"
                  (click)="onSelectWaypoint(waypoint)"
                  [waypoint]="waypoint">
                </pilot-search-card>
              }
            </div>
          }
        }

      @case (State.Loading) {
        <fly-loading-spinner
          class="loader"
          message="Retrieving data...">
        </fly-loading-spinner>
      }

      @case (State.NoDataAvailable) {
        <div class="no-data">No results found.</div>
      }

      @case (State.NoSelection) {
        <div class="explanation">
          <span class="body-1-semibold">Search for things like...</span>
          <ul>
            <li>
              <span class="explain-left body-2-semibold">KFCM</span>
              <span class="explain-right body-2"> - Find waypoints by identifier</span>
            </li>
            <li>
              <span class="explain-left body-2-semibold">Minneapolis</span>
              <span class="explain-right body-2"> - Find by city name</span>
            </li>
            <li>
              <span class="body-explain-left body-2-semibold">Flying Cloud</span>
              <span class="explain-right body-2"> - Find by facility name</span>
            </li>
          </ul>
        </div>
      }
    }
  </div>
}
