import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, ElementRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvcMapsDisplayService } from '@garmin-avcloud/avc-maps-display';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { FlightIcons, FlyIconModule, Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { FlyTabsModule } from '@garmin-avcloud/avcloud-ui-common/tabs';
import { AuthService, isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { FlightRouteControllerService, RouteComputedLeg, UnifiedTokenRequest, UnifiedTokenRequestDesiredLocationTypes, UnifiedTokenResponse } from '@generated/flight-route-service';
import { TOKEN_SEARCH_TYPES } from '@shared/constants/flights/flights-constants';
import { AirportDataSection } from '@shared/enums/airport-data-section.enum';
import { CloudTypeDisplayMap } from '@shared/enums/airports/cloud-type.enum';
import { CloudDescription } from '@shared/enums/cloud-description.enum';
import { State } from '@shared/enums/loading-state.enum';
import { AirportSearchResponse } from '@shared/models/airport/search/airport-search-response.model';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { AirportService } from '@shared/services/airport/airport.service';
import { FlightsUnauthenticatedService } from '@shared/services/flights/unauthenticated/flights-unauthenticated.service';
import { SEARCH_BAR_EVENT } from '@shared/tokens/search-bar-event.token';
import { catchError, combineLatest, debounceTime, filter, forkJoin, fromEvent, map, Observable, of, switchMap, tap } from 'rxjs';
import { InfoService, InfoWindowPosition } from '../../services/map-info/info.service';
import { AirportSearchCardComponent } from './airport-search-card/airport-search-card.component';
import { SearchCardComponent } from './search-card/search-card.component';

export interface SearchResult {
  airport?: AirportSearchResult;
  waypoint?: RouteComputedLeg;
}

@Component({
  selector: 'pilot-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    AirportSearchCardComponent,
    CommonModule,
    FormsModule,
    FlyButtonModule,
    FlyInputModule,
    FlyIconModule,
    FlyLoadingSpinnerModule,
    FlyTabsModule,
    ReactiveFormsModule,
    SearchCardComponent,
  ],
})
export class SearchComponent implements OnInit {
  private readonly airportService = inject(AirportService);
  private readonly authService = inject(AuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly el = inject(ElementRef);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly flightsUnauthenticatedService = inject(FlightsUnauthenticatedService);
  private readonly infoService = inject(InfoService);
  private readonly mapService = inject(AvcMapsDisplayService);
  private readonly searchBarEvent = inject(SEARCH_BAR_EVENT);
  private readonly isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });

  readonly Icons = Icons;
  readonly FlightIcons = FlightIcons;
  readonly State = State;
  readonly SEARCH_DEBOUNCE_MS = 500;

  protected showSearchContent: boolean = false;

  readonly searchControl = new FormControl<string>('', { nonNullable: true });
  currentSearchState = State.NoSelection;
  enterPressed = false;

  clickStartedInside = false;
  isMobileOrTabletSize = false;

  showMoreAirports = signal(false);
  showMoreWaypoints = signal(false);
  airports = signal<AirportSearchResult[]>([]);
  waypoints = signal<RouteComputedLeg[]>([]);
  airportsToDisplay = computed(() =>
    this.showMoreAirports()
      ? {
          airports: this.airports(),
          label: `Show Less`,
        }
      : {
          airports: this.airports().slice(0,5),
          label: `Show More (${this.airports().length - 5})`,
        }
  );
  waypointsToDisplay = computed(() =>
    this.showMoreWaypoints()
      ? {
          waypoints: this.waypoints(),
          label: `Show Less`,
        }
      : {
          waypoints: this.waypoints().slice(0,5),
          label: `Show More (${this.waypoints().length - 5})`,
        }
  );

  ngOnInit(): void {
    this.searchBarEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event?.type !== 'airport' && event?.response != null) {
          this.infoService.showWaypoint(event.response, InfoWindowPosition.TopRight);
        }
      });

    this.searchControl.valueChanges.pipe(
      tap((value) => {
        this.airports.set([]);
        this.waypoints.set([]);
        if (!isStringNonEmpty(value)) {
          this.currentSearchState = State.NoSelection;
        } else {
          this.currentSearchState = State.Loading;
        }
      }),
      debounceTime(this.SEARCH_DEBOUNCE_MS),
      filter((search): search is string => search !== ''),
      switchMap((search) => {
        return combineLatest([
          this.airportService.searchAirportsNoWx(search),
          this.getWaypointInfo(search),
        ]).pipe(
          map(([airportsResponse, waypointsResponse]) => ({ search, airportsResponse, waypointsResponse}))
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ search, airportsResponse, waypointsResponse }) => {
      this.setResults(airportsResponse, waypointsResponse, search);

      // reset the enter flag
      this.enterPressed = false;
    });

    /**
     * If a click starts (via the mousedown event) inside and ends
     * outside, the click event will be fired from the closest common
     * ancestor of each:
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event
     *
     * We still want the show/hide behavior to happen after mouseup,
     * so ignore the case when the click starts inside and is dragged
     * outside before releasing the mouse button.
     */
    fromEvent(document, 'mousedown')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        this.clickStartedInside = this.containsEvent(event);
      });

    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        let parent = event.target.parentNode;
        let infoClicked = false;

        // Don't close the search bar if we clicked airport/waypoint info
        while (parent != null && !infoClicked) {
          if (parent.className === 'info-column') {
            infoClicked = true;
          }
          parent = parent.parentNode;
        }

        if (!this.containsEvent(event) && !this.clickStartedInside && !infoClicked) {
          this.hideContent();
        }

        event.stopPropagation();
      });
  }

  protected handleEnter(): void {
    this.enterPressed = true;
    const firstAirport = this.airports().at(0);
    const firstWaypoint = this.waypoints().at(0);
    if (firstAirport != null) {
      this.onSelectAirport(firstAirport);
      this.enterPressed = false;
    } else if (firstWaypoint != null) {
      this.onSelectWaypoint(firstWaypoint);
      this.enterPressed = false;
    }
  }

  /**
   * Checks if an event originated from inside this component,
   * including the fly-icon inside the fly-input, which is not part
   * of the node tree.
   *
   * @param event an event from `fromEvent`, such as a click or mousedown event
   * @returns true if the event came from inside this component, otherwise false
   */
  containsEvent(event: any): boolean {
    return (this.el.nativeElement.contains(event.target) === true
      || [
        event.target.className, // <fly-icon>
        event.target.parentNode?.className, // <svg>
        event.target.parentNode?.parentNode?.className // <use>
      ].includes('cancel-icon'));
  }

  hideContent(): void {
    this.showSearchContent = false;
  }

  showContent(): void {
    this.showSearchContent = true;
  }

  onSelectAirport(airport: AirportSearchResult): void {
    this.searchBarEvent.next({
      type: 'airport',
      response: airport,
    });

    this.showSearchContent = false;

    this.updateMap(airport.lat, airport.lon);
    this.clearResults();
  }

  onSelectWaypoint(waypoint: RouteComputedLeg): void {
    this.searchBarEvent.next({
      type: 'waypoint',
      response: waypoint,
    });

    this.showSearchContent = false;

    if (waypoint.lat != null && waypoint.lon != null) {
      this.updateMap(waypoint.lat, waypoint.lon);
    }
    this.clearResults();
  }

  onCancelSearch(): void {
    this.searchControl.reset();
    this.showSearchContent = false;
  }

  private clearResults(): void {
    this.searchControl.reset();
    this.airports.set([]);
    this.waypoints.set([]);
  }

  private updateMap(lat: number, lon: number): void {
    this.mapService.highlight.location.set([{ latitude: lat, longitude: lon }]);
    this.mapService.state.fit({
      point: { latitude: lat, longitude: lon },
      minZoom: 10,
      maxZoom: 10
    });
  }

  private getWaypointInfo(waypointId?: string, countryCode?: string, exactSearch?: boolean): Observable<UnifiedTokenResponse> {
    if (waypointId != null) {
      const request: UnifiedTokenRequest = {
        name: waypointId,
        countryCode,
        exactSearch: exactSearch ?? false,
        // Ignore the airports found from here, since we have the airport service
        desiredLocationTypes: TOKEN_SEARCH_TYPES.filter((token) =>
          token !== UnifiedTokenRequestDesiredLocationTypes.AIRPORT)
      };
      if (this.isAuthenticated()) {
        return this.flightRouteService.unifiedTokenSearchPost(request);
      } else {
        return this.flightsUnauthenticatedService.getUnifiedTokenResponse(request);
      }
    } else {
      console.error('Unable to search for waypoint with no identifier');
      return of({ resultsList: [] });
    }
  }

  private setResults(
    airportsResponse: AirportSearchResponse | null,
    waypointsResponse: UnifiedTokenResponse | null,
    searchText: string,
  ): void {
    const airports = airportsResponse?.airports ?? [];
    const waypoints = waypointsResponse?.resultsList ?? [];

    if (airports.length === 0) {
      if (waypoints.length === 0) {
        this.currentSearchState = State.NoDataAvailable;
        return;
      }
      this.currentSearchState = State.Loaded;
    }

    if (searchText !== this.searchControl.value) return;
    this.showMoreAirports.set(false);
    this.showMoreWaypoints.set(false);

    // TODO: Temporary while airport search worker limit parameter is disfunctional.
    airports.splice(20);
    this.airports.set(
      airports.reduce((result, airport) => {
        if (airport != null && [airport.icao, airport.naa].includes(searchText.toUpperCase())) {
          result.unshift(airport);
          if (airport?.icao === searchText.toUpperCase() && this.enterPressed) {
            this.onSelectAirport(airport);
          }
        } else {
          result.push(airport);
        }
        return result;
      }, [] as AirportSearchResult[])
    );

    this.waypoints.set(
      waypoints.reduce((result, waypoint) => {
        if (waypoint != null && waypoint.identifier === searchText.toUpperCase()) {
          // If enter has been pressed, select the matching result
          if (this.enterPressed) {
            this.onSelectWaypoint(waypoint);
          }
          result.unshift(waypoint);
      } else {
        result.push(waypoint);
      }
      return result;
      }, [] as RouteComputedLeg[])
    );

    if (this.enterPressed) {
      this.clearResults();
      return;
    }

    // Add weather data to airports
    const airportFetch = airports.map((a) => this.airportService.getAirportInfoById(a.icao as string, [AirportDataSection.METAR])
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
    );
    forkJoin(airportFetch)
      .subscribe((metarData) => {
        for (const airport of metarData) {
          const currentIndex = metarData.indexOf(airport);
          if (this.airports().length > 0) {
            this.airports.update((airportList) => {
              const cloudType = airport?.metar?.metar.clouds;
              airportList[currentIndex].clouds = cloudType != null ? CloudTypeDisplayMap[cloudType] as CloudDescription : 'Missing';
              airportList[currentIndex].category = airport?.metar?.metar.airfieldRating ?? 'NO WX';
              return airportList;
            });
          }
        }
        this.currentSearchState = State.Loaded;
      });

  }
}
