import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  pure: true,
  name: 'flyToNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: string): number {
    const result = Number(value);
    if (isNaN(result)) {
      throw new Error(`${value} is not a number.`);
    }
    return result;
  }
}
