import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '@environment';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { Icon, Icons, FlightIcons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputComponent, FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { AuthService, isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { RouteComputedLeg, RouteComputedLegLocationType } from '@generated/flight-orchestrator-service';
import { FlightRouteControllerService, UnifiedTokenRequest, UnifiedTokenRequestDesiredLocationTypes, UnifiedTokenResponse } from '@generated/flight-route-service';
import { TOKEN_SEARCH_TYPES } from '@shared/constants/flights/flights-constants';
import { State } from '@shared/enums/loading-state.enum';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { AirportService } from '@shared/services/airport/airport.service';
import { Observable, debounceTime, filter, forkJoin, fromEvent, switchMap, tap } from 'rxjs';
import { ExtendedTitleCasePipe } from "../../../pipes/extended-title-case/extended-title-case.pipe";
import { AirportSearchCardComponent } from '../airport-search-card/airport-search-card.component';
import { SearchCardComponent } from '../search-card/search-card.component';
import { SearchResult } from '../search.component';
import { getWaypointIcon } from '../search.utils';

@Component({
  selector: 'pilot-settings-search',
  templateUrl: './settings-search.component.html',
  styleUrls: ['./settings-search.component.scss'],
  standalone: true,
  imports: [
    AirportSearchCardComponent,
    CommonModule,
    FlyButtonModule,
    FlyInputModule,
    FlyIconModule,
    FlyLoadingSpinnerModule,
    ReactiveFormsModule,
    SearchCardComponent,
    ExtendedTitleCasePipe
  ]
})
export class SettingsSearchComponent implements OnInit, OnChanges {
  @Input() label: string = "";
  @Input() loadedWaypoint: RouteComputedLeg | null;
  @Output() readonly waypointSent: EventEmitter<SearchResult> = new EventEmitter();

  @ViewChild(FlyInputComponent) private readonly input: FlyInputComponent;

  private readonly airportService = inject(AirportService);
  private readonly authService = inject(AuthService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly el = inject(ElementRef);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly http = inject(HttpClient);

  readonly SEARCH_DEBOUNCE = 500;
  readonly Icons = Icons;
  readonly FlightIcons = FlightIcons;
  readonly State = State;

  protected showSearch: boolean = true;
  protected showSearchContent: boolean = false;

  waypointSelected: boolean = false;
  waypointChosen: SearchResult = {};
  icon: Icon;
  displayedSubName: string;

  readonly errorMap = {
    required: 'Required Value.'
  };

  formGroup = new FormGroup({
    searchControl: this.formBuilder.control<string>('', { validators: Validators.required, nonNullable: true })
  });

  currentSearchState: State = State.NoSelection;

  isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });
  searchResponse = toSignal(this.formGroup.controls.searchControl.valueChanges.pipe(
    tap((value) => {
      if (!isStringNonEmpty(value)) {
        this.currentSearchState = State.NoSelection;
        return;
      } else {
        this.currentSearchState = State.Loading;
      }
    }),
    debounceTime(this.SEARCH_DEBOUNCE),
    filter((search): search is string => search !== ''),
    switchMap((search) => {
      const airports = this.airportService.searchAirports(search);
      const waypoints = this.getWaypointInfo(search);
      return forkJoin({
        airportsResponse: airports,
        waypointsResponse: waypoints
      });
    })), { initialValue: null });

  airports = computed(() => this.searchResponse()?.airportsResponse?.airports ?? []);
  waypoints = computed(() => this.searchResponse()?.waypointsResponse?.resultsList ?? []);
  displayOrder: number[] = [];

  constructor() {
    effect(() => {
      const airports = this.airports();
      const waypoints = this.waypoints();
      if (this.currentSearchState !== State.NoSelection) {
        if (airports.length > 0 || waypoints.length > 0) {
          this.orderResponse();
          this.currentSearchState = State.Loaded;
        } else {
          this.currentSearchState = State.NoDataAvailable;
        }
      }
    });
  }

  ngOnInit(): void {
    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        /*
         * If the click is inside this component, including the
         * input's cancel-icon, which isn't included in the node tree
         */
        if (this.el.nativeElement.contains(event.target) === false
          && ![
            event.target.className, // <fly-icon>
            event.target.parentNode?.className, // <svg>
            event.target.parentNode?.parentNode?.className // <use>
          ].includes('cancel-icon')) {
          this.hideContent();
        }

        event.stopPropagation();
      });
  }

  get isShowingContent(): boolean {
    return this.showSearchContent;
  }

  ngOnChanges(): void {
    this.onDeleteWaypoint();
    this.onLoadCard();
  }

  hideContent(): void {
    this.showSearchContent = false;
  }

  showContent(): void {
    this.showSearchContent = true;
  }

  onOpenSearch(): void {
    this.showSearch = true;
    this.cdr.detectChanges();
    this.input.inputEl.nativeElement.focus();
  }

  onSelectAirport(airport: AirportSearchResult): void {
    this.waypointSelected = true;
    this.waypointChosen.airport = airport;
    this.waypointSent.emit(this.waypointChosen);
  }

  onSelectWaypoint(waypoint: RouteComputedLeg): void {
    this.waypointSelected = true;
    this.waypointChosen.waypoint = waypoint;
    const wpt = this.waypointChosen.waypoint;
    if (wpt != null) {
      this.icon = getWaypointIcon(
        {
          type: wpt.locationType ?? '',
          identifier: wpt.identifier,
          navaidType: wpt.navaidType
        }
      ) as Icon;
      this.displayedSubName = wpt.locationType ?? wpt.displayName ?? '';
    }
    this.waypointSent.emit(this.waypointChosen);
  }

  onLoadCard(): void {
    if (this.loadedWaypoint != null && this.loadedWaypoint.identifier != null) {
      if (this.loadedWaypoint.locationType === RouteComputedLegLocationType.AIRPORT) {
        this.airportService.getAirportsByIds([this.loadedWaypoint.identifier]).pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((response) => {
            for (const airport of response?.airports ?? []) {
              if (airport.icao === this.loadedWaypoint?.identifier) {
                this.waypointChosen.airport = airport;
                this.waypointSent.emit(this.waypointChosen);
                this.waypointSelected = true;
              }
            }
          });
      } else {
        this.getWaypointInfo(this.loadedWaypoint.identifier, true)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((waypoints) => {
            if (waypoints.resultsList != null) {
              this.waypointChosen.waypoint = waypoints.resultsList.find((waypoint) =>
                waypoint.countryCode === this.loadedWaypoint?.countryCode && waypoint.locationType === this.loadedWaypoint?.locationType);
              this.waypointSent.emit(this.waypointChosen);
              if (this.waypointChosen.waypoint != null) {
                this.icon = getWaypointIcon(
                  {
                    type: this.waypointChosen.waypoint.locationType ?? '',
                    identifier: this.waypointChosen.waypoint.identifier,
                    navaidType: this.waypointChosen.waypoint.navaidType
                  }
                ) as Icon;
                this.displayedSubName = this.waypointChosen.waypoint.locationType ?? this.waypointChosen.waypoint.displayName ?? '';
                this.waypointSelected = true;
              }
            }
          });
      }
    }
  }

  onCancelSearch(): void {
    this.formGroup.controls.searchControl.setValue('');
  }

  getWaypointInfo(waypointId: string, exactSearch?: boolean): Observable<UnifiedTokenResponse> {
    const request: UnifiedTokenRequest = {
      name: waypointId,
      exactSearch: exactSearch ?? false,
      // Ignore the airports found from here, since we have the airport service
      desiredLocationTypes: TOKEN_SEARCH_TYPES.filter((token) =>
        token !== UnifiedTokenRequestDesiredLocationTypes.AIRPORT)
    };
    if (this.isAuthenticated()) {
      return this.flightRouteService.unifiedTokenSearchPost(request);
    } else {
      return this.http.post<UnifiedTokenResponse>(
        `${environment.garmin.aviation.workerApiHost}/flights/token-search-unauthenticated`,
        request
      );
    }
  }

  private orderResponse(): void {
    /*
    A very rough estimator of boosting search results based on if
    an identifier or name match exactly to the search term
    The first n entries of displayOrder correspond to the order of the airports,
    and the next m entries correspond to the order of the waypoints
    */
    const n = this.airports().length;
    const m = this.waypoints().length;
    let lastIndex = 0;
    this.displayOrder = new Array(n + m).fill(n + m + 1); // n+m+1 is the end
    const search = this.formGroup.controls.searchControl.value.toLowerCase();
    this.airports().forEach((airport, i) => {
      const airportId = airport.icao ?? airport.iata ?? airport.naa ?? '';
      if (airportId.toLowerCase() === search) {
        this.displayOrder[i] = lastIndex;
        lastIndex += 1;
      } else if (airport.name.toLowerCase() === search) {
        this.displayOrder[i] = lastIndex;
        lastIndex += 1;
      }
    });
    this.waypoints().forEach((wpt, i) => {
      if (wpt.identifier.toLowerCase() === search) {
        this.displayOrder[n + i] = lastIndex;
        lastIndex += 1;
      }
    });
  }

  onDeleteWaypoint(): void {
    this.waypointChosen.waypoint = undefined;
    this.waypointChosen.airport = undefined;
    this.waypointSelected = false;
  }
}
