import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AvcMapsDisplayModule } from '@garmin-avcloud/avc-maps-display';
import { FlyAutocompleteModule } from '@garmin-avcloud/avcloud-ui-common/autocomplete';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { FlyCheckboxModule } from '@garmin-avcloud/avcloud-ui-common/checkbox';
import { FlyCollapsibleModule } from '@garmin-avcloud/avcloud-ui-common/collapsible';
import { FlyDatePickerModule } from '@garmin-avcloud/avcloud-ui-common/date-picker';
import { FlyFileUploadModule } from '@garmin-avcloud/avcloud-ui-common/file-upload';
import { FlyFilteringSelectModule } from '@garmin-avcloud/avcloud-ui-common/filtering-select';
import { FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLinkModule } from '@garmin-avcloud/avcloud-ui-common/link';
import { FlyListDataModule } from '@garmin-avcloud/avcloud-ui-common/list-data';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { FlyModalModule } from '@garmin-avcloud/avcloud-ui-common/modal';
import { FlyRadioModule } from '@garmin-avcloud/avcloud-ui-common/radio';
import {
  FlySegmentedControllerComponent,
  FlySegmentedControllerModule,
} from '@garmin-avcloud/avcloud-ui-common/segmented-controller';
import { FlySelectModule } from '@garmin-avcloud/avcloud-ui-common/select';
import { FlySharedModule } from '@garmin-avcloud/avcloud-ui-common/shared';
import { FlySimpleTooltipComponent } from '@garmin-avcloud/avcloud-ui-common/simple-tooltip';
import { FlySliderModule } from '@garmin-avcloud/avcloud-ui-common/slider';
import { FlyTabsModule } from '@garmin-avcloud/avcloud-ui-common/tabs';
import { FlyToastModule } from '@garmin-avcloud/avcloud-ui-common/toast';
import { FlyTooltipModule } from '@garmin-avcloud/avcloud-ui-common/tooltips';
import { AircraftNotConfiguredComponent } from './components/aircraft-not-configured/aircraft-not-configured.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AirwayModalComponent } from './components/flights/airway-modal/airway-modal.component';
import { AirwayOptionsComponent } from './components/flights/airway-options/airway-options.component';
import { ProcedureOptionsComponent } from './components/flights/procedure-options/procedure-options.component';
import { WaypointIconComponent } from './components/flights/waypoint-icon/waypoint-icon.component';
import { WaypointOptionsComponent } from './components/flights/waypoint-options/waypoint-options.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { AirportSearchCardComponent } from './components/search/airport-search-card/airport-search-card.component';
import { SearchCardComponent } from './components/search/search-card/search-card.component';
import { SearchComponent } from './components/search/search.component';
import { SettingsSearchComponent } from './components/search/settings-search/settings-search.component';
import { RecentsTabComponent } from './components/search-modal/recents/recents-tab/recents-tab.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { SearchFormComponent } from './components/search-modal/subcomponents/search-form.component';
import { SettingsBrgDisTabComponent } from './components/settings/settings-waypoints-edit-modal/settings-brg-dis-tab/settings-brg-dis-tab.component';
import { SettingsLatLonTabComponent } from './components/settings/settings-waypoints-edit-modal/settings-lat-lon-tab/settings-lat-lon-tab.component';
import { SettingsRadRadTabComponent } from './components/settings/settings-waypoints-edit-modal/settings-rad-rad-tab/settings-rad-rad-tab.component';
import { SettingsWaypointsEditModalComponent } from './components/settings/settings-waypoints-edit-modal/settings-waypoints-edit-modal.component';
import { SubscriptionRequiredModalComponent } from './components/subscription-required-modal/subscription-required-modal.component';
import { TimeAgoComponent } from './components/time-ago/time-ago.component';
import { UpgradeSubscriptionComponent } from './components/upgrade-subscription/upgrade-subscription.component';
import { HeadingArrowDirective } from './directives/heading-arrow/heading-arrow.directive';
import { NavLinkLockDirective } from './directives/nav-link-lock/nav-link-lock.directive';
import { SplitPipe } from './pipes/array/split/split.pipe';
import { CoordinatePipe } from './pipes/coordinate/coordinate.pipe';
import { DayDateTimeRangePipe } from './pipes/day-date-time-range/day-date-time-range.pipe';
import { DirectionPipe } from './pipes/direction/direction.pipe';
import { EnumPipe } from './pipes/enum/enum.pipe';
import { ExtendedTitleCasePipe } from './pipes/extended-title-case/extended-title-case.pipe';
import { FilingStatusModule } from './pipes/filing-status/filing-status.module';
import { LocalDateTimeTimezonePipe } from './pipes/local-date-time-timezone/local-date-time-timezone.pipe';
import { LocalTimeTimezonePipe } from './pipes/local-time-timezone/local-time-timezone.pipe';
import { MosVisibilityPipe } from './pipes/mos-visibility/mos-visibility.pipe';
import { PadStartPipe } from './pipes/pad-num/pad-num.pipe';
import { RoundNumPipe } from './pipes/round-num/round-num.pipe';
import { SecurePipe } from './pipes/secure/secure.pipe';
import { TimeRangePipe } from './pipes/time-range/time-range.pipe';
import { TimezonePipe } from './pipes/timezone/timezone.pipe';
import { ToNumberPipe } from './pipes/to-number/to-number.pipe';
import { UnitConverterPipe } from './pipes/unit-converter/unit-converter.pipe';
import { WbOptionalEquipmentLabelPipe } from './pipes/w-and-b/wb-optional-equipment-label.pipe';

@NgModule({
  imports: [
    AircraftNotConfiguredComponent,
    AirportSearchCardComponent,
    AvcMapsDisplayModule,
    CommonModule,
    ExtendedTitleCasePipe,
    FilingStatusModule,
    FlyAutocompleteModule,
    FlyButtonModule,
    FlyCardModule,
    FlyCheckboxModule,
    FlyCollapsibleModule,
    FlyDatePickerModule,
    FlyFileUploadModule,
    FlyFilteringSelectModule,
    FlyIconModule,
    FlyInputModule,
    FlyLinkModule,
    FlyListDataModule,
    FlyLoadingSpinnerModule,
    FlyModalModule,
    FlyRadioModule,
    FlySegmentedControllerModule,
    FlySelectModule,
    FlySharedModule,
    FlySimpleTooltipComponent,
    FlySliderModule,
    FlyTabsModule,
    FlyToastModule,
    FlyTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
    SearchCardComponent,
    SearchComponent,
    SettingsSearchComponent,
    TimeAgoComponent,
    ToNumberPipe,
    UnitConverterPipe,
    UpgradeSubscriptionComponent,
    NavLinkComponent,
    NavLinkLockDirective,
    SecurePipe,
  ],
  exports: [
    AircraftNotConfiguredComponent,
    AirportSearchCardComponent,
    AirwayModalComponent,
    AirwayOptionsComponent,
    AvcMapsDisplayModule,
    ConfirmationModalComponent,
    CoordinatePipe,
    DayDateTimeRangePipe,
    DirectionPipe,
    EnumPipe,
    ExtendedTitleCasePipe,
    FilingStatusModule,
    FlyAutocompleteModule,
    FlyButtonModule,
    FlyCardModule,
    FlyCheckboxModule,
    FlyCollapsibleModule,
    FlyDatePickerModule,
    FlyFileUploadModule,
    FlyFilteringSelectModule,
    FlyIconModule,
    FlyInputModule,
    FlyLinkModule,
    FlyListDataModule,
    FlyLoadingSpinnerModule,
    FlyModalModule,
    FlyRadioModule,
    FlySegmentedControllerComponent,
    FlySelectModule,
    FlySharedModule,
    FlySimpleTooltipComponent,
    FlySliderModule,
    FlyTabsModule,
    FlyToastModule,
    FlyTooltipModule,
    HeadingArrowDirective,
    LocalDateTimeTimezonePipe,
    LocalTimeTimezonePipe,
    MosVisibilityPipe,
    PadStartPipe,
    ProcedureOptionsComponent,
    ReactiveFormsModule,
    RoundNumPipe,
    ScrollingModule,
    SearchCardComponent,
    SearchComponent,
    SearchFormComponent,
    SearchModalComponent,
    SecurePipe,
    SettingsSearchComponent,
    SettingsBrgDisTabComponent,
    SettingsWaypointsEditModalComponent,
    SettingsLatLonTabComponent,
    SettingsRadRadTabComponent,
    SplitPipe,
    SubscriptionRequiredModalComponent,
    TimeAgoComponent,
    TimeRangePipe,
    TimezonePipe,
    ToNumberPipe,
    UnitConverterPipe,
    UpgradeSubscriptionComponent,
    WaypointIconComponent,
    WaypointOptionsComponent,
    WbOptionalEquipmentLabelPipe,
    NavLinkComponent,
    NavLinkLockDirective,
  ],
  declarations: [
    AirwayModalComponent,
    AirwayOptionsComponent,
    ConfirmationModalComponent,
    CoordinatePipe,
    DayDateTimeRangePipe,
    DirectionPipe,
    EnumPipe,
    HeadingArrowDirective,
    LocalDateTimeTimezonePipe,
    LocalTimeTimezonePipe,
    MosVisibilityPipe,
    PadStartPipe,
    ProcedureOptionsComponent,
    RecentsTabComponent,
    RoundNumPipe,
    SearchFormComponent,
    SearchModalComponent,
    SettingsBrgDisTabComponent,
    SettingsWaypointsEditModalComponent,
    SettingsLatLonTabComponent,
    SettingsRadRadTabComponent,
    SplitPipe,
    SubscriptionRequiredModalComponent,
    TimeRangePipe,
    TimezonePipe,
    WaypointIconComponent,
    WaypointOptionsComponent,
    WbOptionalEquipmentLabelPipe,
  ],
})
export class SharedModule {}
