import { MapIcon } from '@garmin-avcloud/avc-maps-display';
import { FlightIcons, Icon } from "@garmin-avcloud/avcloud-ui-common/icon";
import { RouteComputedLegLocationType, RouteComputedLegNavaidType } from "@generated/flight-route-service";
import { RecentWaypoint } from "@shared/services/recent-waypoints/recent-waypoints.constants";
import { MapIcons } from '../../../features/map/constants/map-icons';

export const ICON_MAP = new Map<RouteComputedLegLocationType, Icon>([
  [RouteComputedLegLocationType.AIRWAY, FlightIcons.Navaids.AIRWAY],
  [RouteComputedLegLocationType.INTERSECTION, MapIcons.NAVAID.VFR.WPT],
  [RouteComputedLegLocationType.NDB, MapIcons.NAVAID.VFR.NDB],
  [RouteComputedLegLocationType.VOR, MapIcons.NAVAID.VFR.VOR],
  [RouteComputedLegLocationType.AIRPORT, FlightIcons.Airports.ToweredServices],
  [RouteComputedLegLocationType.ARRIVAL_PROCEDURE, FlightIcons.Navaids.ARRIVAL_PROCEDURE],
  [RouteComputedLegLocationType.DEPARTURE_PROCEDURE, FlightIcons.Navaids.DEPARTURE_PROCEDURE]
]);

export const WAYPOINT_ICON_MAP = new Map<RouteComputedLegLocationType, MapIcon>([
  [RouteComputedLegLocationType.AIRWAY, MapIcon.Airway_Marker],
  [RouteComputedLegLocationType.INTERSECTION, MapIcon.Navaid_WPT],
  [RouteComputedLegLocationType.NDB, MapIcon.Navaid_NDB],
  [RouteComputedLegLocationType.VOR, MapIcon.Navaid_VOR],
  [RouteComputedLegLocationType.AIRPORT, MapIcon.Invalid] // add general airport
]);

export const getWaypointIcon = (waypoint: RecentWaypoint): Icon | string => {
  if (waypoint.type === undefined || waypoint.type.length === 0) {
    return MapIcons.NAVAID.VFR.WPT;
  } else if (waypoint.type === "AIRPORT" && waypoint.icon != null) {
    if (waypoint.icon.length > 0) {
      return waypoint.icon;
    } else {
      return ICON_MAP.get(RouteComputedLegLocationType.AIRPORT) as Icon;
    }
  } else if (waypoint.rnav === true) {
    return MapIcons.NAVAID.VFR.WPT_RNAV;
  }
  else if (waypoint.type === "VOR") {
    switch (waypoint.navaidType as RouteComputedLegNavaidType) {
      case RouteComputedLegNavaidType.DME_ONLY: return MapIcons.NAVAID.VFR.DME;
      case RouteComputedLegNavaidType.TACAN_ONLY: return MapIcons.NAVAID.VFR.TACAN;
      case RouteComputedLegNavaidType.VOR_DME: return MapIcons.NAVAID.VFR.VOR_DME;
      case RouteComputedLegNavaidType.VOR_TAC: return MapIcons.NAVAID.VFR.VORTAC;
      default: return MapIcons.NAVAID.VFR.VOR;
    }
  }
  return ICON_MAP.get(waypoint.type as RouteComputedLegLocationType) ?? MapIcons.NAVAID.VFR.WPT;
};
